var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"specialTasksGradeContent"},[_c('span',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.lesson.lesson.lesson.title)+" ")]),_c('div',{staticClass:"specialTasksGradeContent__answer"},[(
                _vm.lesson.homework &&
                    _vm.lesson.estimatedAll &&
                    (_vm.lesson.userAnswerEstimate || _vm.lesson.bestAnswerEstimate)
            )?_c('div',[(_vm.lesson.userAnswerEstimate)?_c('div',{staticClass:"profileBox profileBox--2"},[_c('p',{staticClass:"profileBox__title2"},[_vm._v(" Ваш ответ ")]),_c('p',{staticClass:"profileBox__text2"},[_vm._v(" "+_vm._s(_vm.lesson.userAnswerEstimate.text)+" ")]),(
                        _vm.lesson.homework.replies.filter(function (reply) {
                            return reply.students_comment;
                        }).length
                    )?_c('div',[_vm._m(0),_vm._l((_vm.lesson.homework.replies),function(reply){return (reply.students_comment)?_c('div',{key:reply._id,staticClass:"specialTasksGradeResult__main"},[_c('div',{staticClass:"specialTasksGradeResult__text"},[_c('div',{staticClass:"specialTasksGradeResult__textContent"},[_c('p',{staticClass:"colorDenim"},[_vm._v(" "+_vm._s(reply.user.firstName)+" "+_vm._s(reply.user.lastName)+" ")]),_c('p',{staticClass:"text2"},[_vm._v(" "+_vm._s(reply.text)+" ")])]),(reply.points)?_c('div',{class:reply.points < 2
                                        ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg specialTasksGradeContent__circle--big'
                                        : reply.points > 4
                                        ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg specialTasksGradeContent__circle--big'
                                        : 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg specialTasksGradeContent__circle--big'},[_vm._v(" "+_vm._s(reply.points)+" ")]):_vm._e()])]):_vm._e()})],2):_vm._e(),_c('p',{staticClass:"profileBox__title2 profileBox__title2--2"},[_vm._v(" Средняя оценка: "),_c('span',{class:_vm.lesson.userAnswerEstimate.pointsAvg < 2
                                ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg specialTasksGradeContent__circle--big'
                                : _vm.lesson.userAnswerEstimate.pointsAvg > 4
                                ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg specialTasksGradeContent__circle--big'
                                : 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg specialTasksGradeContent__circle--big'},[_vm._v(" "+_vm._s(_vm.lesson.userAnswerEstimate.pointsAvg)+" ")])])]):_vm._e(),(_vm.lesson.bestAnswerEstimate)?_c('div',{staticClass:"profileBox profileBox--2 profileBox--orangeBorder specialTasksGradeContent__answer"},[_c('p',{staticClass:"profileBox__title2"},[_vm._v(" Лучший ответ ")]),_c('p',{staticClass:"profileBox__text2"},[_vm._v(" "+_vm._s(_vm.lesson.bestAnswerEstimate.text)+" ")]),_c('hr',{staticClass:"specialTasksGradeContentHr"}),_c('p',{staticClass:"profileBox__title2 profileBox__title2--2"},[_vm._v(" Средняя оценка: "),_c('span',{class:_vm.lesson.bestAnswerEstimate.pointsAvg < 2
                                ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg specialTasksGradeContent__circle--big'
                                : _vm.lesson.bestAnswerEstimate.pointsAvg > 4
                                ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg specialTasksGradeContent__circle--big'
                                : 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg specialTasksGradeContent__circle--big'},[_vm._v(" "+_vm._s(_vm.lesson.bestAnswerEstimate.pointsAvg)+" ")])])]):_vm._e()]):_c('div',{staticClass:"profileBox profileBox--2"},[_c('p',{staticClass:"profileBox__title2 colorRed"},[_vm._v(" Внимание! ")]),_c('p',{staticClass:"profileBox__text2"},[(!_vm.lesson.estimatedAll)?_c('span',[_vm._v(" К сожалению, Вы не успели дать ответ в отведённый промежуток времени. ")]):_c('span',[_vm._v(" К сожалению, Вы не успели дать ответ в отведённый промежуток времени, ознакомьтесь с правильным ответом от Александра Герчика. "),_c('Button',{staticClass:"profileBox__btn2",attrs:{"isLink":true,"link":'/' +
                                _vm.$route.params.slug +
                                '/study/' +
                                _vm.$route.params.block_priority +
                                '/' +
                                _vm.$route.params.lesson_id +
                                '/correct-answer',"title":"смотреть правильный ответ"}})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"specialTasksGradeResult__underline title1"},[_vm._v(" Комментарии других студентов "),_c('span',[_vm._v("оценки")])])}]

export { render, staticRenderFns }