<template>
    <div class="specialTasksGradeContent">
        <span class="h3">
            {{ lesson.lesson.lesson.title }}
        </span>

        <div class="specialTasksGradeContent__answer">
            <div
                v-if="
                    lesson.homework &&
                        lesson.estimatedAll &&
                        (lesson.userAnswerEstimate || lesson.bestAnswerEstimate)
                "
            >
                <div v-if="lesson.userAnswerEstimate" class="profileBox profileBox--2">
                    <p class="profileBox__title2">
                        Ваш ответ
                    </p>
                    <p class="profileBox__text2">
                        {{ lesson.userAnswerEstimate.text }}
                    </p>
                    <div
                        v-if="
                            lesson.homework.replies.filter((reply) => {
                                return reply.students_comment;
                            }).length
                        "
                    >
                        <p class="specialTasksGradeResult__underline title1">
                            Комментарии других студентов
                            <span>оценки</span>
                        </p>

                        <div
                            v-for="reply in lesson.homework.replies"
                            :key="reply._id"
                            v-if="reply.students_comment"
                            class="specialTasksGradeResult__main"
                        >
                            <div class="specialTasksGradeResult__text">
                                <div class="specialTasksGradeResult__textContent">
                                    <p class="colorDenim">
                                        {{ reply.user.firstName }}
                                        {{ reply.user.lastName }}
                                    </p>
                                    <p class="text2">
                                        {{ reply.text }}
                                    </p>
                                </div>
                                <div
                                    v-if="reply.points"
                                    :class="
                                        reply.points < 2
                                            ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg specialTasksGradeContent__circle--big'
                                            : reply.points > 4
                                            ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg specialTasksGradeContent__circle--big'
                                            : 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg specialTasksGradeContent__circle--big'
                                    "
                                >
                                    {{ reply.points }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="profileBox__title2 profileBox__title2--2">
                        Средняя оценка:
                        <span
                            :class="
                                lesson.userAnswerEstimate.pointsAvg < 2
                                    ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg specialTasksGradeContent__circle--big'
                                    : lesson.userAnswerEstimate.pointsAvg > 4
                                    ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg specialTasksGradeContent__circle--big'
                                    : 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg specialTasksGradeContent__circle--big'
                            "
                        >
                            {{ lesson.userAnswerEstimate.pointsAvg }}
                        </span>
                    </p>
                </div>

                <div
                    v-if="lesson.bestAnswerEstimate"
                    class="profileBox profileBox--2 profileBox--orangeBorder specialTasksGradeContent__answer"
                >
                    <p class="profileBox__title2">
                        Лучший ответ
                    </p>
                    <p class="profileBox__text2">
                        {{ lesson.bestAnswerEstimate.text }}
                    </p>
                    <hr class="specialTasksGradeContentHr" />
                    <p class="profileBox__title2 profileBox__title2--2">
                        Средняя оценка:
                        <span
                            :class="
                                lesson.bestAnswerEstimate.pointsAvg < 2
                                    ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg specialTasksGradeContent__circle--big'
                                    : lesson.bestAnswerEstimate.pointsAvg > 4
                                    ? 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg specialTasksGradeContent__circle--big'
                                    : 'text colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg specialTasksGradeContent__circle--big'
                            "
                        >
                            {{ lesson.bestAnswerEstimate.pointsAvg }}
                        </span>
                    </p>
                </div>
            </div>

            <div v-else class="profileBox profileBox--2">
                <p class="profileBox__title2 colorRed">
                    Внимание!
                </p>
                <p class="profileBox__text2">
                    <span v-if="!lesson.estimatedAll">
                        К сожалению, Вы не успели дать ответ в отведённый промежуток
                        времени.
                    </span>
                    <span v-else>
                        К сожалению, Вы не успели дать ответ в отведённый промежуток
                        времени, ознакомьтесь с правильным ответом от Александра Герчика.

                        <Button
                            :isLink="true"
                            :link="
                                '/' +
                                    $route.params.slug +
                                    '/study/' +
                                    $route.params.block_priority +
                                    '/' +
                                    $route.params.lesson_id +
                                    '/correct-answer'
                            "
                            class="profileBox__btn2"
                            title="смотреть правильный ответ"
                        />
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";

export default {
    name: "userSpecialTasksGrade",

    components: {
        Button,
    },

    async beforeMount() {
        await this.updateLessonStore();

        if (
            (this.lesson &&
                this.lesson.lesson &&
                this.lesson.lesson.lesson &&
                this.lesson.lesson.lesson.type !== "additional") ||
            this.lesson.additionalLessonStatusByDate === "close" ||
            this.lesson.additionalLessonStatusByDate === "closeEarly" ||
            this.lesson.additionalLessonStatusByDate === "unknown"
        ) {
            this.$router.push(
                `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}`
            );
        }
    },

    computed: {
        lesson: function() {
            return (
                (this.$store.getters.getLesson && this.$store.getters.getLesson.lesson) ||
                {}
            );
        },
    },

    data() {
        return {};
    },

    methods: {
        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import "./userSpecialTasksGrade.scss";
</style>
